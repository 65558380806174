import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import InputMask from "react-input-mask";

import Layout from "../components/Layout";
import * as S from "../styles/indexPage/styles";

import IndexBlog from "../components/IndexBlog";

import SlidesShow from "../components/SlideShow";
import HistoryBlock from "../components/HistoryBlock";
import VisionMissionBlock from "../components/VisionMissionBlock";

import StaticImageSlide from "../../static/img/slide-03.png";

export const IndexPageTemplate = ({ intro }) => (
  <div>
    <img src={StaticImageSlide} alt="" style={{display: "none"}} />
    <S.Slides>
      <SlidesShow
        gridItems={[
          ...intro.slides,
          {
            image: StaticImageSlide,
            title: "slide 3",
            position: "center",
            typeSlide: "fixed",
          },
        ]}
      />
    </S.Slides>
    <S.MainHistory>
      <HistoryBlock gridItems={intro.history} />
    </S.MainHistory>
    <S.MainVisionMission>
      <VisionMissionBlock gridItems={intro.visionMission} />
    </S.MainVisionMission>
    <S.IndexBlog>
      <div className="container">
        <div className="title">
          <h2>Últimos posts</h2>
          <div className="border"></div>
        </div>
        <IndexBlog />
        <div className="linkBlog">
          <Link to="/blog">Ir para o blog</Link>
        </div>
      </div>
    </S.IndexBlog>
    <S.HealthInsurance>
      <div className="title">
        <h2>Convênios</h2>
        <div className="border"></div>
      </div>
      <div className="HealthInsuranceBlock">
        {intro.healthInsurance.map((img) => (
          <div className="HealthInsuranceItem">
            <Img fluid={img.image.childImageSharp.fluid} />
          </div>
        ))}
      </div>
    </S.HealthInsurance>
    <S.Form >
      <div className="formBg">
        <div className="formContainer">
          <h2>Quer receber informações e ficar por dentro das novidades?</h2>
          <form className="inputBlock" action="/success" name="newsletter" data-netlify="true">
            <input type="hidden" name="form-name" value="newsletter"/>
            <input name="Nome" type="text" placeholder="Nome" required/>
            <input name="Email"type="email" placeholder="Email" required/>
            <InputMask
              nome="Telefone" 
              placeholder="Whatsapp"
              mask="(99) 99999-9999"
              required
              maskChar={null}
            />
            <button className="btn">Enviar</button>
          </form>
        </div>
      </div>
    </S.Form>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout bg="transparent">
      <IndexPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        intro {
          slides {
            image {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
            position
            typeSlide
            buttonText
            buttonLink
          }
          history {
            image {
              childImageSharp {
                fluid(maxWidth: 758) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
          }
          visionMission {
            image {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title1
            text1
            title2
            text2
          }
          healthInsurance {
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      timeToRead
    }
  }
`;
