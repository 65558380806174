import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import * as S from "../styles/slides/styles";

const SlideShow = ({ gridItems }) => {
  const [indexSlide, setIndexSlide] = useState(0);
  const { image, title, text, buttonText, buttonLink } = gridItems[indexSlide];

  useEffect(() => {
    const next = (indexSlide + 1) % gridItems.length;
    const id = setTimeout(() => setIndexSlide(next), 3000);
    return () => clearTimeout(id);
  }, [indexSlide]);

  return gridItems[indexSlide].typeSlide &&
    gridItems[indexSlide].typeSlide === "fixed" ? (
    <S.SlideFixed key={title} className="slide slideFixed">
      <div>
        <img src={image} key={title} alt={title} />
      </div>
      <div className="slideText">
        <div className="positionCenter sf">
          <h2>Estamos nas redes sociais</h2>
          <div className="socialLinks">
              <div>
                <a href="https://www.instagram.com/nucleolaboratorioceresgo/">
                  <S.GrayInstagram />
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/nucleolaboratorioceres/">
                  <S.GrayFacebook />
                </a>
              </div>
            </div>
            <p className="socialP">@nucleolaboratorio</p>
        </div>
      </div>
      <div className="slideMenu">
        {gridItems.map((item, index) => (
          <button onClick={() => setIndexSlide(index)}>
            {index === indexSlide ? (
              <div className="slideBtn active"></div>
            ) : (
              <div className="slideBtn"></div>
            )}
          </button>
        ))}
      </div>
    </S.SlideFixed>
  ) : (
    <div key={title} className="slide">
      <div>
        <Img fluid={image.childImageSharp.fluid} key={title} alt={title} />
      </div>
      <div className={`${
            gridItems[indexSlide].position === "center" ? "slideTextCenter" : "slideText"
          }`}>
        <div
          className={`${
            gridItems[indexSlide].position === "center" ? "positionCenter" : ""
          }`}
        >
          <h1>{title}</h1>
          <p>{text}</p>
          <a href={buttonLink} className="btnSlide">{buttonText}</a>
        </div>
      </div>
      <div className="slideMenu">
        {gridItems.map((item, index) => (
          <button onClick={() => setIndexSlide(index)}>
            {index === indexSlide ? (
              <div className="slideBtn active"></div>
            ) : (
              <div className="slideBtn"></div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

SlideShow.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
      buttonText: PropTypes.string,
      buttonLink: PropTypes.string,
    })
  ),
};

export default SlideShow;
