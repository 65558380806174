import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const VisionMissionBlock = ({ gridItems }) => {
  return (
    <div className="containerVisionMission">
      <div className="visionMissionTextBlock">
        <div className="visionMissionTextItem">
          <div className="title">
            <h2>
              {gridItems.title1}
              <div className="border"></div>
            </h2>
          </div>
          <div className="textHistory">
            <p>{gridItems.text1}</p>
          </div>
        </div>
        <div className="visionMissionTextItem">
          <div className="title">
            <h2>
              {gridItems.title2}
              <div className="border"></div>
            </h2>
          </div>
          <div className="textHistory">
            <p>{gridItems.text2}</p>
          </div>
        </div>
      </div>
      <div className="containerImg">
        <div className="imgBg imgVisionMission">
          <Img
            fluid={gridItems.image.childImageSharp.fluid}
            key={gridItems.title1}
            alt={gridItems.title1}
          />
        </div>
      </div>
    </div>
  );
};

VisionMissionBlock.propTypes = {
  gridItems: PropTypes.objectOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default VisionMissionBlock;
