import styled from "styled-components";

import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Facebook } from "@styled-icons/boxicons-logos/Facebook";

export const SlideFixed = styled.div`
position: relative;
display: flex;
  .slideText{
    left: 0 !important;
   display: flex;
   justify-content: center;
   width: 100%;
    .sf{
      max-width: 798px;
      margin: 0 auto;
      h2{ 
      font-weight: bold;
      font-size: 64px;
      line-height: 77px;
      text-align: center;
      color: #FFFFFF;
      @media only screen and (max-width: 1400px) {
        font-size: 56px;
        line-height: 100%;
      }
      @media only screen and (max-width: 800px) {
        font-size: 40px;
      }
      }
  .socialLinks{
    display: flex;
    justify-content: center;
    padding: 40px 0 39px;
    a{
      padding: 30px 20px;
      background: rgba(255, 255, 255, 0.07);
      border-radius: 4px;
      &:first-child{
        margin-right: 32px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .socialP{
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: rgba(255, 255, 255, 0.5) !important;
    @media only screen and (max-width: 1400px) {
      font-size: 16px;
    }
  }
}
}
`;

export const GrayInstagram = styled(Instagram)`
  color: rgba(255, 255, 255, 0.5);
  height: 42px;
`;

export const GrayFacebook = styled(Facebook)`
  color: rgba(255, 255, 255, 0.5);
  height: 42px;
`;