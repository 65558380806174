import styled from "styled-components";

export const Slides = styled.section`
  position: relative;
  margin-top: -112px;
  .slide {
    position: relative;
    z-index: 2;
    width: 100%;
    .gatsby-image-wrapper {
      height: calc(100vh - 41px - 67px);
      max-width: 100%;
      img {
        position: relative;
        max-height: 100vh;
        max-width: 100%;
      }
    }
    .slideText {
      position: absolute;
      top: 0;
      z-index: 4;
      top: 35%;
      left: 17%;

      .positionCenter {
        justify-content: center;
        
   
          h1 {
            text-align: center;
          }
          p {
            text-align: center;
          }
        }


      h1 {
        font-weight: bold;
        font-size: 96px;
        line-height: 116px;
        color: #ffffff;
        @media only screen and (max-width: 1400px) {
          font-size: 56px;
          line-height: 100%;
        }
        @media only screen and (max-width: 800px) {
          font-size: 40px;
        }
      }
      p {
        font-size: 32px;
        line-height: 39px;
        color: rgba(255, 255, 255, 0.7);
        @media only screen and (max-width: 1400px) {
          font-size: 16px;
        }
      }
      .btnSlide{
        padding: 20px 104px;
        border: 2px solid #69CF67;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        display: inline-block;
        margin-top: 20px;
        &:hover{
          background: rgba(0, 0, 0, 0.1);
        }
        @media only screen and (max-width: 800px) {
          padding: 20px 50px;
        }
      }
    }
    .slideTextCenter{
      position: absolute;
      top: 0;
      z-index: 4;
      top: 35%;
      left: 0 !important;
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: center;
      h1 {
        font-weight: bold;
        font-size: 96px;
        line-height: 116px;
        color: #ffffff;
        @media only screen and (max-width: 1400px) {
          font-size: 56px;
          line-height: 100%;
        }
        @media only screen and (max-width: 800px) {
          font-size: 40px;
        }
      }
      p {
        font-size: 32px;
        line-height: 39px;
        color: rgba(255, 255, 255, 0.7);
        @media only screen and (max-width: 1400px) {
          font-size: 16px;
        }
      }
      .btnSlide{
        padding: 20px 104px;
        border: 2px solid #69CF67;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        display: inline-block;
        margin-top: 20px;
        &:hover{
          background: rgba(0, 0, 0, 0.1);
        }
        @media only screen and (max-width: 800px) {
          padding: 20px 50px;
        }
      }
    }
    .slideMenu {
      z-index: 3;
      position: absolute;
      bottom: 66px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      button {
        border-radius: 25px;
        margin: 0 6px;
        cursor: pointer;
        border: none;
        outline: none;
        .slideBtn {
          border-radius: 25px;
          width: 12px;
          height: 12px;
          background: rgba(255, 255, 255, 0.2);
        }
        .active {
          background: #fff;
        }
      }
    }

  }
  .slideFixed {
    img {
      width: 100%;
      height: calc(100vh - 41px - 67px);
    }
  }
`;

export const MainHistory = styled.section`
  padding: 150px 0;
  background: #e5e5e5;
  @media only screen and (max-width: 1400px) {
    padding: 150px 0 91px;
  }
  .containerHistory {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 116px;
    align-items: center;
    @media only screen and (max-width: 1400px) {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }

    .title {
      padding-bottom: 61px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media only screen and (max-width: 1400px) {
        align-items: center;
      }
    }
    .imgHistory {
      position: relative;
      max-width: 770px;
      height: auto;
      @media only screen and (max-width: 1400px) {
        padding-right: 20px;
      }
    }
  }
  .textHistory {
    max-width: 736px;
    @media only screen and (max-width: 1400px) {
      padding: 43px 24px 0px;
      max-width: 1400px;
    }

    p {
      font-size: 20px;
      line-height: 159.52%;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.5);
      @media only screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 159.52%;
      }
    }
  }
`;

export const MainVisionMission = styled.section`
  padding: 104px 0;
  display: flex;
  justify-content: flex-end;
  background: #e5e5e5;
  @media only screen and (max-width: 1400px) {
    padding: 0;
  }
  .containerVisionMission {
    max-width: 1700px;
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    @media only screen and (max-width: 1400px) {
      grid-template-columns: 1fr;
      grid-gap: 47px;
      width: 100%;
      justify-content: center;
    }
    @media only screen and (max-width: 900px) {
      grid-gap: 0;
    }
    .visionMissionTextBlock {
      display: flex;
      padding-left: 40px;
      @media only screen and (max-width: 1400px) {
        padding: 0 40px 47px;
        display: flex;
        justify-content: center;
      }
      @media only screen and (max-width: 900px) {
        flex-direction: column;
        padding: 0;
        margin: 0 auto;
      }
      .visionMissionTextItem {
        width: 352px;
        @media only screen and (max-width: 900px) {
          width: 100%;
          padding: 0 24px;
        }
        &:first-child {
          margin-right: 100px;
        }
        .title {
          padding-bottom: 54px;
          @media only screen and (max-width: 900px) {
            display: flex;
            justify-content: center;
          }
          @media only screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 39px;
          }
        }
        p {
          font-size: 16px;
          line-height: 159.52%;
          letter-spacing: 0.05em;
          color: rgba(0, 0, 0, 0.5);
          @media only screen and (max-width: 900px) {
            padding-bottom: 78px;
          }
        }
      }
    }
    .containerImg {
      position: relative;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 800px) {
        width: 100%;
      }
      @media only screen and (max-width: 615px) {
        height: 350px;
      }

      .imgVisionMission {
        height: 488px;
        padding-left: 125px;
                  flex-grow: 1;
        @media only screen and (max-width: 1400px) {

          padding-left: 0;
        }
        @media only screen and (max-width: 900px) {
          padding-left: 28px;
          height: 450px;
        }
        @media only screen and (max-width: 615px) {
          width: 100%;
          height: 350px;
        }
        .gatsby-image-wrapper{
          @media only screen and (max-width: 615px) {
            height: 350px;
          }
          img {
            border-radius: 24px 0 0 24px;

          }
        }
       
      }
    }
  }
`;

export const IndexBlog = styled.section`
  padding: 88px 0;
  background: #e5e5e5;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blogBlock {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 56px 0 64px;
    @media only screen and (max-width: 1101px) {
      grid-template-columns: 1fr;
      grid-gap: 64px;
    }
    @media only screen and (max-width: 480px) {
      grid-gap: 24px;
    }
  }
  .linkBlog {
    text-align: center;
    padding-top: 20px;
    a {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 24px 79px;
      background-color: #69cf67;
      border-radius: 8px;
      &:hover {
        background-color: #59ba57;
      }
    }
  }
`;

export const HealthInsurance = styled.section`
  padding: 126px 0;
  background: #e5e5e5;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 103px;
  } 
  .HealthInsuranceBlock {
    max-width: 1120px;
    margin: 0 auto;
    display: grid;
    grid-gap: 40px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    @media only screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr;
      padding: 0 32px;
    }
    @media only screen and (max-width: 800px) {
      grid-gap: 40px;
      grid-template-columns: 1fr;
      padding: 0 24px;
    }
    .HealthInsuranceItem {
      display: flex;
      justify-content: center;
      align-items: center;
      .gatsby-image-wrapper{
        width: 210px;
      }
    }
  }
`;

export const Form = styled.div`
  padding: 105px 32px;
  background: #e5e5e5;
  @media only screen and (max-width: 800px) {
    padding: 90px 0 77px;
  }
  .formBg {
    background-image: url(../../img/bgForm.png);
    max-width: 1504px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    box-shadow: 0px 17px 20px -30px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    @media only screen and (max-width: 800px) {
      border-radius: 0;
    }
    .formContainer {
      padding: 109px 100px;
      @media only screen and (max-width: 800px) {
        padding: 71px 24px 81px;
      }
      h2 {
        font-weight: bold;
        font-size: 56px;
        line-height: 68px;
        color: #ffffff;
        padding-bottom: 39px;
        @media only screen and (max-width: 800px) {
          font-size: 24px;
          line-height: 29px;
          padding-bottom: 41px;
        }
      }
      .inputBlock {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr;
        grid-gap: 27px;
        @media only screen and (max-width: 1200px) {
          grid-template-columns: 2fr 2fr;
        }
        @media only screen and (max-width: 800px) {
          grid-template-columns: 1fr;
        }
        input {
          padding: 24px 30px;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #fff;
          outline: none;
          border: none;
          border: 2px solid rgba(255, 255, 255, 0.2);
          border-radius: 8px;
          background: transparent;
          width: 259px;
          @media only screen and (max-width: 1400px) {
            width: 100%;
          }
          &::placeholder {
            color: #fff;
          }
        }
        .btn {
          background: #69cf67;
          border-radius: 8px;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          padding: 24px 30px;
          width: 259px;
          @media only screen and (max-width: 1400px) {
            width: 100%;
          }
          &:hover {
            background: #59ba57;
          }
        }
      }
    }
  }
`;
