import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const HistoryBlock = ({ gridItems }) => {
  return (
    <div className="containerHistory">
      <div>
        <div className="title">
          <h2>
            {gridItems.title}
            <div className="border"></div>
          </h2>
        </div>
        <div className="imgBg imgHistory">
          <Img
            fluid={gridItems.image.childImageSharp.fluid}
            key={gridItems.title}
            alt={gridItems.title}
          />
        </div>
      </div>
      <div className="textHistory">
        <p>{gridItems.text}</p>
      </div>
    </div>
  );
};

HistoryBlock.propTypes = {
  gridItems: PropTypes.objectOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default HistoryBlock;
