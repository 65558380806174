import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class IndexBlog extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="blogBlock">
        {posts
          .sort((a, b) => {
            const dateAPieces = a.node.frontmatter.publicationDate.split("/");
            const dateBPieces = b.node.frontmatter.publicationDate.split("/");
            const dateA = new Date(
              dateAPieces[2],
              dateAPieces[1],
              dateAPieces[0]
            );
            const dateB = new Date(
              dateBPieces[2],
              dateBPieces[1],
              dateBPieces[0]
            );
            return dateA > dateB;
          })
          .slice(0, 3)
          .map(({ node: post }) => (
            <div className="blogItem" key={post.id}>
              <div>
                {post.frontmatter.featuredimage ? (
                  <div className="itemImg">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      }}
                    />
                  </div>
                ) : null}
                <div className="itemContainer">
                  <div className="ItemTagDate">
                    <p style={{ background: post.frontmatter.background }}>
                      {post.frontmatter.category}
                    </p>
                    <span className="">{post.frontmatter.publicationDate}</span>
                  </div>
                  <div className="itemTitle">
                    <p>{post.frontmatter.title}</p>
                  </div>
                  <div className="itemTimeLink">
                    <p>{post.timeToRead} min de leitura</p>
                    <Link className="" to={post.fields.slug}>
                      ler post
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

IndexBlog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query IndexBlogQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                publicationDate
                category
                background
                featuredimage {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
            }
          }
        }
      }
    `}
    render={(data, count) => <IndexBlog data={data} count={count} />}
  />
);
